//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-488:_2668,_2636,_3684,_7144,_1088,_6132,_4924,_8312;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-488')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-488', "_2668,_2636,_3684,_7144,_1088,_6132,_4924,_8312");
        }
      }catch (ex) {
        console.error(ex);
      }